.reviews{
    min-height: 80vh;
    background-color: #F58D9A;
    padding: 50px;
}

.reviews h1{
    padding-top: 50px;
    font-size: 50px;
    line-height: 50px;
    font-family: 'Chelsea Market', sans-serif;
    color: #fff;
}