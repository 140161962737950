/* .itemStatus p, small, h5{
    color: white;
}

.itemStatus .itemDiv {
    border-radius: 10px !important;
    margin: 100px 50px;
} */

.ActionDelete svg:hover{
    color: red;
    cursor: pointer;
}