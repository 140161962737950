
body{
    margin: 0;
    padding: 0;
}
.HeaderSection{
    background-color: #FCDE64;
    /* background-color: hsla(0, 0%, 100%, 0.767); */
    height: 100vh;
    width: 100%; 
} 

.row{
    margin: 0 !important;
}
.HeaderSection .column-height{
    height: 100vh;
}

.HeaderSection h2{
    font-size: 50px;
    line-height: 50px;
    font-family: 'Chelsea Market', sans-serif;
}
