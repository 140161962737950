 .TeacherIcon ul{
    list-style: none;
    margin: 0;
    padding: 0;
}

.TeacherIcon ul li{
    display: inline-block;
    margin: auto;
}

.TeacherIcon ul li a{
    color: black;
    background-color: antiquewhite;
    margin-right: 5px;
    border-radius: 5px;
    transition: 1s;
}

.TeacherIcon ul li a:hover{
    color: white;
    background-color: #7DD0F8;
} 

.TeacherIcon{
    display: none;
}

.CardMain:hover .imageTeacher{
    background-color: hsla(0, 0%, 0%, 0.8);
}


.CardMain:hover .TeacherIcon{
    display: block;
}


.TeacherIcon{
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
